import request from '@/utils/request'

// 买家中心银行卡操作、、、

// 获取银行卡列表
export function getbanklist(params){
    return request({
        url:'/trade/buyer/purchaser/bank/list',
        method:'get',
        params
    })
}
// 设置默认银行卡
export function defaultbank(data) {
    return request({
        url: '/trade/buyer/purchaser/bank/set/default',
        method: 'post',
        data
    })
}
// 添加银行卡
export function addbank(data) {
    return request({
        url: '/trade/buyer/purchaser/bank/add',
        method: 'post',
        data
    })
}

// 删除银行卡
export function delbank(data) {
    return request({
        url: '/trade/buyer/purchaser/bank/delete',    
        method: 'post',
        data   
    })
}

