<template>
  <div class="index">
    <div class="info">
      <div class="info-header">
        <b>{{ subject.name }} </b>
      </div>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col :span="8"> 创建账号：{{ subject.nickName }}</a-col>
        <a-col :span="8"> 企业编码：{{ subject.enterpriseCode }} </a-col>
        <a-col :span="8"> 签署邮箱：{{ subject.signEmail }}</a-col>
        <a-col :span="8" class="group-col"> 关联集团：
          <a-tooltip placement="top">
            <template slot="title">
                <span>{{subject.mapList | groupAll}}</span>
            </template>
            {{ subject.mapList | group }} 
          </a-tooltip>
        </a-col>
        <a-col :span="8"> 成立时间：{{ subject.licenseRegTime }} </a-col>
        <a-col :span="8"> 入驻时间：{{ subject.createdTime }} </a-col>
        <a-col :span="8"></a-col>
      </a-row>
    </div>

    <div class="tabs">
      <!-- tabs -->
      <a-tabs v-model="tabs" @change="tabsChange" @tabClick="tabk">
        <!-- <a-tab-pane key="1" tab="待办任务"> </a-tab-pane> -->

        <a-tab-pane key="2" tab="财务管理">
          <div class="container" style="padding-bottom: 72px">
            <p class="tabs-title">发票管理</p>

            <a-row :gutter="16" type="flex" align="middle">
              <a-col :span="12">发票抬头</a-col>
              <a-col :span="12">纳税人识别号</a-col>
              <a-col :span="12" class="fs16">{{ billInfo.supplierName }}</a-col>
              <a-col :span="12" class="fs16">{{ billInfo.unifiedCode }}</a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="12"><span class="red">*</span> 发票银行名称</a-col>
              <a-col :span="12"><span class="red">*</span> 发票银行账号</a-col>
              <a-col :span="12"
                ><a-input
                  v-if="isEdit"
                  v-model="billInfo.invoiceBankName"
                /><span v-else>{{ billInfo.invoiceBankName }}</span></a-col
              >
              <a-col :span="12">
                <span
                  ><a-input
                    v-if="isEdit"
                    :maxLength="30"
                    v-model="billInfo.invoiceBankAcc"
                  /><span v-else>{{ billInfo.invoiceBankAcc }}</span></span
                >
              </a-col>
            </a-row>

            <a-row :gutter="16">
              <a-col :span="12"><span class="red">*</span> 发票电话</a-col>
              <a-col :span="12"><span class="red">*</span> 发票地址</a-col>
              <a-col :span="12"
                ><a-input
                  v-if="isEdit"
                  :maxLength="18"
                  v-model="billInfo.invoiceMobile"
                   @input="billInfo.invoiceMobile=$event.target.value.replace(/[^\d|-]/g,'')"
                /><span v-else>{{ billInfo.invoiceMobile }}</span></a-col
              >
              <a-col :span="12"
                ><a-input v-if="isEdit" v-model="billInfo.invoiceAddr" /><span
                  v-else
                  >{{ billInfo.invoiceAddr }}</span
                ></a-col
              >
            </a-row>

            <a-row :gutter="16">
              <a-col :span="24"><span class="red">*</span> 电子发票邮箱</a-col>
              <a-col :span="12"
                ><a-input v-if="isEdit" v-model="billInfo.invoiceEmail" /><span
                  v-else
                  >{{ billInfo.invoiceEmail }}</span
                ></a-col
              >
              <a-col :span="12">
                <a-button type="primary" v-if="!isEdit" @click="isEdit = true"
                  >修改</a-button
                >
                <a-space v-if="isEdit">
                  <a-button type="primary" @click="updateinvoice()"
                    >确定</a-button
                  >
                  <a-button v-if="isEdit" @click="cancel">取消</a-button>
                </a-space>
              </a-col>
            </a-row>

            <p class="tabs-title" style="margin-top: 42px">支付渠道</p>

            <a-row>
              <a-col :span="2">网关支付：</a-col>
              <a-col
                :span="5"
                align="center"
                style="border: 1px solid #ccc; border-radius: 5px"
                ><img :src="require(`../../assets/images/u154.png`)" alt=""/></a-col>
            </a-row>           
            <a-form-model ref="bankList" v-bind="formItemLayoutWithOutLabel">
              <a-form-model-item
                class="form-item"
                v-for="(item, index) in bankList"
                :key="item.id"
                v-bind="index === 0 ? formItemLayout : {}"
                :label="index === 0 ? '付款账号' : ''"
              >
                <a-col :span="4"
                  ><a-input disabled v-model="item.bankName" placeholder=""
                /></a-col>
                <a-col :span="1" align="center">--</a-col>
                <a-col :span="8">
                  <a-input
                    disabled
                    v-model="item.bankAccount"
                    placeholder=""
                    style="width: 100%; margin-right: 8px"
                  />
                </a-col>
                <a-col :span="3" v-if="item.isDefault === 1" align="center"
                  >默认付款</a-col
                >
                <a-col
                  :span="3"
                 
                  v-else
                  align="center"
                  ><span class="cursor" @click="defaultCard(item)">设为默认</span> </a-col
                >
                <a-popconfirm title="确认删除?" @confirm="removeDomain(item)" v-if="item.isDefault !== 1">
                  <a-icon
                    v-if="bankList.length > 1"
                    class="dynamic-delete-button"
                    type="minus-circle-o"
                    :disabled="bankList.length === 1"
                    @confirm="() => cancel()"
                  />
                </a-popconfirm>
              </a-form-model-item>
              <a-form-model-item v-if="addText">
                <a-col :span="4">
                  <!-- <a-input v-model="bankCard.bankName" placeholder="请输入银行名称"/> -->
                  <a-select v-model="bankCard.bankName" placeholder="请选择银行">
                    <a-select-option v-for="item in politics" :key="item">
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="1" align="center">--</a-col>
                <a-col :span="8">
                  <a-input
                    v-model="bankCard.bankAccount"
                    placeholder="请输入银行账号"
                    :maxLength="30"
                    style="width: 100%; margin-right: 8px"
                    @input="bankCard.bankAccount=$event.target.value.replace(/[^\d]/g,'')"
                  />
                </a-col>
                <a-col :span="4" align="right">
                  <a-button
                    type="primary"
                    style="height: 35px"
                    :loading="loading"
                    @click="addBankCard"
                    >添加银行卡</a-button
                  >
                </a-col>
              </a-form-model-item>
              <a-form-model-item
                v-bind="formItemLayoutWithOutLabel"
                v-if="!addText"
              >
                <a-button
                  type="dashed"
                  style="width: 60%"
                  @click="addText = true"
                >
                  + 添加
                </a-button>
              </a-form-model-item>
            </a-form-model>

            <div class="balance-pay">
              <span> 余额支付： </span>
              <a-space :size="32">
              <img src="../../assets/images/unit1.png" :class="paObj.status === 0 && 'opacity'" alt="">
              <span v-if="paObj.status !== 0" class="balance">￥ {{paObj.totalBalance}}</span>
              <a-popconfirm
                v-if="paObj.status === 0"
                title="请确定是否开通"
                @confirm="openPay"
              >
                <a-button  class="balance-btn noOpen" type="danger" :loading="subAccountLoading">开通余额支付</a-button>
              </a-popconfirm>
              <a-button v-if="paObj.status === 1 && !paObj.enableContinueBindCard" type="primary" class="balance-btn noBind" @click="$refs.entityBind.toBind()">提现绑卡</a-button>
              <template v-if="paObj.status === 1 && paObj.enableContinueBindCard">
                <a-button type="primary" class="balance-btn noBind" @click="$refs.entityBind.toAAA()">继续鉴权</a-button>
                <a-button type="primary" class="balance-btn noBind" @click="$refs.entityBind.toBind()">重新绑卡</a-button>
              </template>
              <template v-if="paObj.status === 2">
                <a-button type="danger" class="balance-btn isBind" @click="$router.push(`/buyercenter/balance-management?entityId=${$route.query.entityId}`)">前往管理</a-button>
                <a-popconfirm
                  title="请确定是否解绑"
                  @confirm="unbind"
                >
                  <a-button  class="balance-btn" :loading="unbindLoading">解绑</a-button>
                </a-popconfirm>
              </template>
              </a-space>
              <a-row>
                <a-form-model-item
                  class="pa-form-item"
                  :style="paObj.status === 0 && 'visibility: hidden;'"
                  v-bind="formItemLayout"
                  :label="paObj.status === 1 && paObj.enableContinueBindCard ? '鉴权账号' : '绑定账号'"
                >
                  <a-col :span="4"><a-input disabled :value="paObj.enableContinueBindCard ? paObj.continueBindBankInfo.bankName : paObj.bankName"/></a-col>
                  <a-col :span="1" align="center">--</a-col>
                  <a-col :span="8">
                    <a-input
                      disabled
                      placeholder=""
                      :value="paObj.enableContinueBindCard ? paObj.continueBindBankInfo.bankAccount : paObj.bankAccount"
                      style="width: 100%; margin-right: 8px"
                    />
                  </a-col>
                </a-form-model-item>
              </a-row>
            </div>

            <div>
              <span>支付密码：</span>
              <span class="font-gray" style="margin-right: 48px"
                >在账户资金变动、订单支付时，需要输入的支付密码</span
              >
              <a v-if="paObj.status === 1 || paObj.status === 2" @click="$refs.payPwd.visible = true">设置</a>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="权限管理">
          <Authority :name="subject.name" :id="subject.id" />
        </a-tab-pane>
        <a-tab-pane key="4" tab="签署设置">
          <SignSet v-if="tabs == 4" ref="set" />
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 注销纳税人弹窗 -->
    <a-modal v-model="modalOff" title="确认提示" centered class="off-model">
      <a-icon type="question-circle" class="fl" />
      <div class="modal">
        <b>注销确认</b>
        <p>确定要将 胶州张三再生资源回收站 注销吗？</p>
        <p class="bottom-text">
          您目前还有 <span>1</span> 笔订单未完成，请前往 <a>订单管理</a> 确认
        </p>
      </div>
      <template slot="footer">
        <a-button key="back" @click="modalOff = false"> 取消 </a-button>
        <a-button key="submit" type="primary" disabled> 确定 </a-button>
      </template>
    </a-modal>

    <a-modal v-model="isVisible" title="交易需知（重要）" centered :footer="null" width="600">
      <MesModal ></MesModal>
    </a-modal>

    <entityBindStep class="entitybind" ref="entityBind" :entityBank="bankList.length && bankList" @ok="paInfo();"/>
    <open-pay ref="openSuccess" @ok="$refs.entityBind.toBind()"></open-pay>
    <payPwd ref="payPwd"/>
  </div>
</template>
<script>
import MesModal from '@/components/mesModal'
import openPay from '@/components/paBind/openPay'
// import entityBind from '@/components/paBind/entityBind'
import entityBindStep from '@/components/paBind/entityBindStep'
import payPwd from '@/components/payPwd'
import SignSet from './components/SignSet.vue'
import Authority from './components/Authority.vue'
import { subject } from '../../api/buyerCenter'
import { getsupplierinf, updateinvoice, messagePush } from '@/api/seller'
import { getbanklist, defaultbank, addbank, delbank } from '@/api/bank'
import {paInfo, openSubAccountPurchaser, purchaserUnbind} from '@/api/pa'
const regEmail = /.{1,}@.{1,}\..{1,}/
const politics = [
  '浙商银行',
  '工商银行',
  '中国银行',
  '建设银行',
  '招商银行',
  '民生银行',
  '华夏银行',
  '浦发银行',
  '平安银行',
  '广发银行',
  '农业银行',
  '邮储银行',
  '兴业银行',
  '南京银行'
]
export default {
  components: {
    SignSet,
    Authority,
    openPay,
    entityBindStep,
    payPwd,
    MesModal
  },
  data() {
    return {
      politics,
      modalOff: false,
      isEdit: false,
      isVisible: false, //显示 交易需知（重要）
      subject: {
        id: null, //
        defaultBankMobile: '', //联系电话
        type: '', //企业类型
        status: '', // 状态
        signEmail: '',
        enterpriseCode: '',
        name: '', //企业名称
        nickName: '', //账号
        licenseRegTime: '', //注册时间
        createdTime: '' //入驻时间
      },
      billInfo: {
        supplierName: '', //发票抬头
        unifiedCode: '', //纳税人识别号
        invoiceBankName: '', //开票银行号
        invoiceBankAcc: '', //发票账户
        invoiceMobile: '', //手机号
        invoiceEmail: '', //电子邮箱
        invoiceAddr: '' //开票地址
      },
      tabs: '2',
      addBank: {
        off: false
      },
      isDefault: 1,
      bankList: [], //银行卡列表
      defaultBank: {
        bankName: undefined,
        bankAccount: undefined,
        isDefault: 1
      },
      bankCard: {
        //添加银行卡
        bankName: undefined,
        bankAccount: ''
      },
      addText: false, //
      editPwd: {
        off: false,
        switchCode: false,
        count: 60
      },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 }
        }
      },
      loading:false,
      subAccountLoading: false,
      unbindLoading: false,
      paObj: {
          off: false,
          status: 0,
          continueBindBankInfo: {},
          enableContinueBindCard: false,
          bankAccount: '',
          bankName: ''
      }
    }
  },
  filters: {
    groupAll(val){
      if (val && val.length) {
        let str = ''
        str = val.map(item=>item.groupName).join(',')
        return str
      }
      return ''
    },
    group(val) {
      if (val && val.length) {
        let str = ''
        // val.forEach((item,index) => {
        //   str+=item.groupName + ((index !== val.length -1 ? ',' : ''))
        // })
        if(val.length>3){
          str = val.map(item=>item.groupName).slice(0,3).join(',') +'...'
        }else{
          str = val.map(item=>item.groupName).join(',')
        } 
        return str
      }
      return ''
    }
  },
  created() {
    this.getsubject()
    this.$store.dispatch('getuserMenu')
    this.getsupplierinf()
    this.paInfo()
    this.getMessagePush()
  },
  methods: {
    //消息推送管理--交易需知
    getMessagePush(){
      messagePush({
        entityType:'P',
        entityId: this.$route.query.entityId
      }).then(res=>{
        if (res.code ===200) {
          let { isMessagePush } = res.data
          if(isMessagePush=='YES'){
            this.isVisible = true
          }else{
            this.isVisible = false
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    tabsChange(key) {
      this.tabs = key
      if (key == 2) {
        this.isEdit = false
        this.getsupplierinf()
      }
    },
    // 获取主体内容
    getsubject() {
      subject({
        entityCharacter: 'P',
        entityId: this.$route.query.entityId
      }).then((res) => {
        this.subject.name = res.data.name
        this.subject.id = res.data.id
        this.subject.enterpriseCode = res.data.code
        this.subject.nickName = res.data.nickName
        this.subject.licenseRegTime = res.data.licenseRegTime
        this.subject.createdTime = res.data.createdTime
        this.subject.signEmail = res.data.signEmail
        console.log(res.data.mapList)
        this.subject.mapList = res.data.mapList
      })
      getbanklist({ purchaserId: this.$route.query.entityId }).then((res) => {
        this.bankList = res.data
      })
    },
    //  发票管理
    getsupplierinf() {
      getsupplierinf({
        entityCharacter: 'P',
        id: this.$route.query.entityId
      }).then((res) => {
        if (res.code === 200) {
          this.billInfo = { ...this.billInfo, ...res.data }
        }
      })
    },
    paInfo () {
      paInfo({type: 'P', entityId: this.$route.query.entityId}).then(res => {
        if (res.code === 200) {
          this.paObj.totalBalance = res.data.totalBalance
          this.paObj.status = res.data.balanceStatus
          this.paObj.continueBindBankInfo = res.data.continueBindBankInfo
          this.paObj.bankAccount = res.data.bankAccount
          this.paObj.bankName = res.data.bankName
          this.paObj.enableContinueBindCard = res.data.enableContinueBindCard
        } else {
          this.$message.error(res.message)
        }
      })
    },
     openPay () {
       this.subAccountLoading = true
       openSubAccountPurchaser({purchaserId: this.$route.query.entityId}).then(res => {
        this.subAccountLoading = false
        if (res.code ===200) {
          this.paInfo()
          this.$refs.openSuccess.isPay = true
        } else {
          this.$message.error(res.message)
        }
       })
     },
    unbind () {
       this.unbindLoading = true
       purchaserUnbind({purchaserId: this.$route.query.entityId}).then(res => {
        this.unbindLoading = false
        if (res.code ===200) {
          this.paInfo()
        } else {
          this.$message.error(res.message)
        }
       })
     },
    //  修改开票信息
    updateinvoice() {
      const myreg =/(^\d{2,4}-\d{7,8}$)|(^\d{2,4}\d{7,8}$)|(^\d{11}$)/
      if (!this.billInfo.invoiceBankName) {
        this.$message.error('发票银行名称不能为空')
      } else if (!this.billInfo.invoiceBankAcc) {
        this.$message.error('发票银行账户不能为空')
      } else if (!myreg.test(this.billInfo.invoiceMobile)) {
        this.$message.error('填写正确的发票手机号')
      } else if (!regEmail.test(this.billInfo.invoiceEmail)) {
        this.$message.error('请输入正确的电子发票邮箱')
      } else if (!this.billInfo.invoiceAddr) {
        this.$message.error('发票地址不能为空')
      } else
        return updateinvoice({
          entityCharacter: 'P',
          id: this.$route.query.entityId,
          ...this.billInfo
        }).then((res) => {
          if (res.code === 200) {
            this.isEdit = false
            this.getsupplierinf()
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    // 添加银行卡
    addBankCard() {
      if (!this.bankCard.bankName) {
        this.$message.error('请填写银行卡名称')
        return
      } else if (!this.bankCard.bankAccount) {
        this.$message.error('请填写银行卡账号')
        return
      } else {
        this.loading=true
         addbank({
          ...this.bankCard,
          purchaserId: this.$route.query.entityId
        }).then((res) => {
          this.loading=false
          if (res.code === 200) {
            this.addText = false
            this.bankCard.bankName=undefined,
            this.bankCard.bankAccount=''
            this.getsubject()
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
        }
    },
    cancel() {
      this.isEdit = false
      this.getsupplierinf()
    },
    // 设置默认银行卡
    defaultCard(item) {
      defaultbank({
        purchaserBankAccountId: item.id,
        purchaserId: this.$route.query.entityId
      }).then((res) => {
        if (res.code === 200) {
          this.getsubject()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 删除银行卡
    removeDomain(item) {
      delbank({ purchaserBankAccountId: item.id }).then((res) => {
        if (res.code === 200) {
          this.getsubject()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取验证码
    sendMsg() {
      this.editPwd.switchCode = true
      this.countDown()
    },
    countDown() {
      if (this.editPwd.count > 0) {
        this.editPwd.count--
        setTimeout(this.countDown, 1000)
      } else {
        this.editPwd.switchCode = false
        this.editPwd.count = 60
      }
    },
    tabk(e) {
      if (e == 4) {
        this.$nextTick(() => {
          this.$refs.set && (this.$refs.set.rele = true)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.index {
  width: 100%;
  border-left: none;
  .info {
    padding: 20px;
    background: #fff;
    .info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      b {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }
  .tabs {
    .font-gray {
      color: #313131;
    }
    .container {
      width: 100%;
      border-top: 1px solid #e9e9e9;
      background: #fff;
      padding: 22px 32px;
      font-size: 14px;
      .tabs-title {
        font-size: 16px;
        font-weight: 650;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 12px;
      }
      /deep/ .ant-row {
        margin-bottom: 16px;
      }
      .bank-card {
        background-color: #5bb0ff;
        border-radius: 5px;
        padding: 16px 0;
        padding-left: 54px;
        position: relative;
        color: #fff;
        .bank-code {
          font-size: 12px;
          margin-bottom: 16px;
        }
        .bank-num {
          font-size: 16px;
        }
        .icon {
          position: absolute;
          left: 0;
          width: 42px;
          height: 42px;
          background: #bfeef7;
          border: 1px solid rgba(0, 0, 0, 0.8);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 8px;
          img {
            width: 35px;
            height: 35px;
          }
        }
        .select {
          position: absolute;
          right: 5px;
          bottom: 5px;
          i {
            cursor: pointer;
            &:hover {
              color: @error;
            }
          }
        }
        .select-check {
          color: @error;
        }
      }
      .bank-bind {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e1e1e1;
        border-radius: 5px;
        height: 100%;
        color: #fff;
        cursor: pointer;
        font-size: 32px;
      }
      .balance-pay {
        margin: 20px 0 28px;
        .opacity {
          opacity: .5;
        }
        img {
          width: 160px;
          border: 1px solid #aaaaaa;
          border-radius: 10px;
        }
        .balance-btn {
          height: 34px;
          border-radius: 4px;
          font-size: 16px;
        }
        .noOpen {
            background-color: rgba(170, 170, 170, 1);
            border-color: rgba(170, 170, 170, 1);
            width: 147px;
        }
        .noBind {
          background-color: rgba(128, 128, 255, 1);
          border-color: rgba(128, 128, 255, 1);
          width: 119px;
        }
        .isBind {
          background-color: rgba(184, 116, 26, 1);
          border-color: rgba(184, 116, 26, 1);
          width: 119px;
        }
        .balance {
          font-size: 36px;
          color: @error;
          margin: 16px;
        }
      }
    }
  }
}
.off-model {
  .fl {
    font-size: 32px;
    color: #fe9400;
  }
  .modal {
    margin-left: 42px;
    b {
      font-size: 16px;
      color: rgb(102, 102, 102);
    }
    p {
      color: #999;
    }
    .bottom-text {
      span {
        font-size: 20px;
        color: #b8741a;
      }
    }
  }
  /deep/ .ant-modal-body {
    padding: 8px 24px 24px 24px;
  }
  /deep/ .ant-modal-header {
    border-bottom: none;
  }
}
.add-bank {
  .sub {
    width: 100%;
    justify-content: center;
    margin-bottom: 24px;
  }
  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
}
.red {
  color: red;
}
.fs16 {
  color: #000;
}
.cursor {
  cursor: pointer;
  color: @primary;
}
/deep/ .ant-col {
  font-size: 14px;
  margin-bottom: 12px;
}
.group-col {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/deep/ .ant-tabs-nav-scroll {
  padding: 0 20px;
  background: #fff;
}
/deep/ .ant-tabs-bar {
  margin: 0;
}
/deep/ .form-item {
  margin-bottom: 0 !important;
}
.entitybind {
    /deep/ .ant-col {
        margin-bottom: 0;
    }
}
.pa-form-item {
  margin-top: 28px;
}
</style>