<template>
  <div class="signset">
    <div class="mainbody" v-if="rele">
      <!--  -->
      <div class="sign-title">公章设置</div>
      <a-row type="flex">
        <span>签署公章：</span>
        <div class="clearfix" style="min-width: 500px">
          <a-upload
            :customRequest="() => ''"
            @change="customRequest"
            accept="image/*"
            list-type="picture-card"
            :file-list="purchaserSealList"
            @preview="handlePreview"
            :remove="onSealDelete"
            :disabled="!loading"
          >
            <div v-if="purchaserSealList.length < 8 && loading" @click="type = 'default'">
              <a-row>
                <a-icon type="plus" />
              </a-row>
              <div class="ant-upload-text">点击上传</div>
            </div>
            <a-icon v-if="!loading" style="font-size: 30px" type="loading" />
          </a-upload>
          <div class="message-text">
            <span class="tit">上传公章规格要求：</span
            >170*170PX，背景透明或白色的图片，若不上传公章，则系统自动根据企业名称生成圆形的图形章使用
          </div>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
        <a-col :span="24" class="auto-sign">
          <a-space>
            <a-checkbox :checked="isAutomaticSigning" @change="checkChange">自动签署</a-checkbox>
            <span class="hint">开启该功能时，则不发签署短信，电子合同自动签署，若签署人有上传手写签名图片则使用该签名签署，否则系统自动生成印章进行签署</span>
          </a-space>
        </a-col>
      </a-row>
      <!-- <div class="sign-title">默认签署人</div>
      <a-row type="flex" align="middle" :gutter="20">
        <a-col :flex="1">
          签署人姓名:
          <a-input
            v-if="sign"
            v-model="defaultSigner.defaultSignerName"
            class="sign-input"
          ></a-input
          ><span v-else>{{ defaultSigner.defaultSignerName }}</span>
        </a-col>
        <a-col :flex="1">
          身份证号:
          <a-input
            v-if="sign"
            v-model="defaultSigner.defaultSignerIdCard"
            :maxLength="18"
            class="sign-input"
          ></a-input>
          <span v-else>{{ defaultSigner.defaultSignerIdCard }}</span>
        </a-col>
        <a-col :flex="1">
          手机号:
          <a-input
            v-if="sign"
            v-model="defaultSigner.defaultSignerMobile"
            :maxLength="11"
            @input="defaultSigner.defaultSignerMobile=$event.target.value.replace(/[^\d]/g,'')"
            class="sign-input"
          ></a-input
          ><span v-else>{{ defaultSigner.defaultSignerMobile }}</span>
        </a-col>

        <a-col :flex="1">
          <a-space>
            <span>手写签名:</span>
            <a-upload
              :showUploadList="false"
              accept="image/*"
              :customRequest="() => ''"
              @change="customRequest($event, defaultSigner.id)"
            >
            <div class="signature" @click="type = 'signature'" :style="{backgroundImage: defaultSigner.signatureImgUrl && `url(${defaultSigner.signatureImgUrl})`}">
              <a-icon v-if="!defaultSigner.signatureImgUrl" type="plus" />
              <a-icon v-else type="swap" class="swap"></a-icon>
            </div>
            </a-upload>
          </a-space>
        </a-col>

        <a-col :span="24" align="right">
          <a-button v-if="!sign" type="primary " @click="sign = true"
            >修改签署人</a-button
          >
          <span v-else><a-button type="primary" @click="updateSingner()">确认</a-button
            >&nbsp;&nbsp;<a-button
              @click="
                getfindsupplierall()
                sign = false
              "
              >取消</a-button></span>
            </a-col
        >
      </a-row> -->
      <a-row>
        <a-col :span="23" align="right">
          <!-- 与关联签署人关联以外的卖家发生交易时，订单签署人为默认签署人 -->
        </a-col>
      </a-row>
      <div class="sign-title">签署人管理</div>
      <!--  -->
      <a-row>
        <!-- <a-col :span="1" class="lh32">搜索:</a-col>
        <a-col :span="12" align="left"
          ><a-input-search
            placeholder="可模糊搜索姓名、关联卖家"
            style="width: 430px"
            @search="onSearch"/>
            </a-col> -->
      </a-row>
      <a-table
        :loading="loadingtable"
        :data-source="signToryVoList"
        :columns="columns"
        :pagination="false"
        rowKey="id"
      >
        <template slot="isDefaultTitle">
          <div class="title-table">默认签署人
            <a-tooltip>
            <template slot="title">
              与未被关联的卖家产生交易时，都由默认签署人签署电子合同
            </template>
            <a-icon class="title-icon" type="question-circle" theme="filled" />
          </a-tooltip>
            </div>
        </template>
        <template slot="relCountTitle">
          <div class="title-table">关联卖家数量
            <a-tooltip>
            <template slot="title">
              签署人关联卖家后，与关联卖家产生交易时，都由该签署人签署电子合同
            </template>
            <a-icon class="title-icon" type="question-circle" theme="filled" />
          </a-tooltip>
            </div>
        </template>
        <template
          v-for="col in ['signerName', 'signerIdCard', 'signerMobile']"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col">
            <a-input
              v-if="record.editable"
              :value="text"
              :maxLength="col | length"
              @change="(e) => handleChange(e.target.value, record.id, col)"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>

        <template slot="isLegal" slot-scope="text">
          <a-icon
            v-if="text"
            type="check-circle"
            theme="filled"
            class="font-error"
          />
        </template>
        <template slot="isDefault" slot-scope="text">
          <a-icon
            v-if="text == 1"
            type="check-circle"
            theme="filled"
            class="font-error"
          />
        </template>
        <template slot="signatureImgUrl" slot-scope="text, row">
          <a-upload
              v-if="!row.editable"
              :customRequest="() => ''"
              :showUploadList="false"
              accept="image/*"
              @change="customRequest($event, row.id)"
            >
            <div class="signature" @click="type = 'signature'" :style="{backgroundImage: text && `url(${text})`}">
              <a-icon v-if="!text" type="plus" />
              <a-icon v-else type="swap" class="swap"></a-icon>
            </div>
            </a-upload>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space v-if="record.editable">
            <a-icon type="check" @click="() => save(record)"></a-icon>
            <a-icon type="close" @click="() => cancel(record.id)"></a-icon>
          </a-space>
          <a-space v-else>
            <template v-if="record.isDefault == 0">
              <a-popconfirm
                cancelText="取消"
                okText="确定"
                title="是否设置为默认签属人?"
                @confirm="updateSingnerNew(record)"
              >
                <a>设为默认</a>
              </a-popconfirm>
              <a :disabled="editingKey !== ''" @click="() => relevance(record.id)">关联卖家</a>
            </template>
            <!-- <a-tooltip
            style="marginTop: 10px"
            placement="right"
            title="Prompt Text"
            :visible="true"
            :auto-adjust-overflow="false"
          >
            <a-button>Ingore / 不处理</a-button>
          </a-tooltip> -->
            <a-popover
              v-if="isReady"
              overlayClassName="popover-custom"
              class="popover"
              :visible="record.uploadCertStatus === 'UNTREATED' && record.isNo"
              placement="right"
            >
            <div slot="content">
              <a-space>
                <a-icon style="color: #faad14" type="exclamation-circle" theme="filled" />
                <span>请补传身份证</span>
                <a-icon type="close" @click="record.isNo = false"/>
              </a-space>
            </div>
            <!-- record.isDefault!==1 -->
            <a @click="newEdit(record)">编辑</a>
            </a-popover>
            <a v-else-if="record.uploadCertStatus === 'SUCCESS'" @click="newEdit">编辑</a>
            <a-popconfirm
             v-if="record.isDefault!==1"
              cancelText="取消"
              okText="确定"
              title="确定删除?"
              :disabled="editingKey !== ''"
              @confirm="() => onDelete(record.id)"
            >
              <a href="javascript:;">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
      <div
        class="table-foot"
        @click="newEdit('')"
        v-if="!signToryVoList.find((item) => item.id === -1)"
      >
        <a-icon type="plus"></a-icon>
      </div>
    </div>
    <Relevance v-if="!rele" :id="id" @rele="rele = $event;getfindsupplierall()"></Relevance>
    <avatar-modal :type="type" ref="modal" @getImageUrl="signatureUrl" @getSealList="getSealList()"/>
    <EditSignModal ref="editSign" :purchaserId="$route.query.entityId" @ok="getfindsupplierall()"/>
  </div>
</template>

<script>
import {
  signtory,
  add,
  updatesignerpurchase,
  updatedefaultsign,
  delsignerpurchaser,
  setdefaultseal,
  list,
  deleteseal,
  signtorySet
} from '@/api/buyerCenter'
import AvatarModal from './AvatarModal.vue'
import EditSignModal from './EditSignModal'
import Relevance from './Relevanc.vue'
import {signerSig,signerConfig} from '@/api/public'
const columns = [
  {
    title: '姓名',
    dataIndex: 'signerName',
    width: 85,
    scopedSlots: { customRender: 'signerName' }
  },
  {
    title: '身份证号',
    dataIndex: 'signerIdCard',
    // width: 200,
    customCell: () => {
      return {
        style: {
          minWidth: '80px'
        }
      };
    },
    scopedSlots: { customRender: 'signerIdCard' }
  },
  {
    title: '手机号',
    dataIndex: 'signerMobile',
    // width: 160,
    customCell: () => {
      return {
        style: {
          minWidth: '40px'
        }
      };
    },
    scopedSlots: { customRender: 'signerMobile' }
  },
  {
    dataIndex: 'isDefault',
    customCell: () => {
      return {
        style: {
          minWidth: '30px'
        }
      };
    },
    slots: { title: 'isDefaultTitle' },
    scopedSlots: { customRender: 'isDefault' }
  },
  {
    customCell: () => {
      return {
        style: {
          minWidth: '20px'
        }
      };
    },
    dataIndex: 'relCount',
    slots: { title: 'relCountTitle' },
    scopedSlots: { customRender: 'relCount' }
  },
  {
    title: '手写签名',
    width: 70,
    align:'center',
    dataIndex: 'signatureImgUrl',
    scopedSlots: { customRender: 'signatureImgUrl' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
var myreg = /^[1][3-9][0-9]{9}$/
var car =/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
export default {
  components: {
    Relevance,
    AvatarModal,
    EditSignModal
  },
  data() {
    this.cacheData = []
    return {
      type: 'default',
      loading: true,
      extSignNum: '', //公章数字
      previewImage: '', //公章图片
      previewVisible: false,
      rele: true,
      sign: false,
      columns,
      editingKey: '',
      // 主键id
      id: null,
      // 默认签署人
      defaultSigner: {
      id: null,
      defaultSignerName: '',
      defaultSignerIdCard: '',
      defaultSignerMobile: ''
      },
      //列表内容
      signToryVoList: [],
      purchaserSealList: [],
      file:'',
      defaultClass: 'ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card',
      isAutomaticSigning: false,
      isReady: false,
      loadingtable: false
    }
  },
  created() {
    this.getfindsupplierall()
    this.getSealList()
  },
  destroyed() {
    // 删除监听印章点击事件
    // eslint-disable-next-line no-debugger
    document.removeEventListener('click', this.clickChange)
  },
  mounted() {
    // 添加印章点击事件
    document.addEventListener('click', this.clickChange)
  },
  filters: {
    length(val) {
      if (!val) return ''
      const obj = {
        signerName: 6,
        signerIdCard: 18,
        signerMobile: 11
      }
      return obj[val] || ''
    }
  },

  methods: {
    setavatar(url) {
      this.previewImage = url
    },
    // 设置默认公章
    clickChange(ev) {
      const that = this
      var target = ev.target || ev.srcElement
      if (target.className === 'ant-upload-list-item-info') {
        that.$confirm({
          title: '提示',
          content: '确定设置成默认公章？',
          onOk: () => {
            const uid = target.getAttribute('uId')
            setdefaultseal({
              sealId: uid,
              purchaserId: that.$route.query.entityId
            }).then((res) => {
              if (res.code === 200) {
                // document.getElementById('icon-default').remove()
                that.$notification.success({
                  message: '成功',
                  description: res.message
                })
                that.getSealList()
              } else {
                that.$notification.error({
                  message: '错误',
                  description: res.message
                })
              }
            })
          },
          onCancel() {}
        })
      }
    },
    // 修改默认签署人
    updateSingner() {
      if (!this.defaultSigner.defaultSignerName) {
        this.$message.error('请输入签署人名称')
      } else if (!car.test(this.defaultSigner.defaultSignerIdCard)) {
        this.$message.error('请输入签署人身份证号')
      } else if (!myreg.test(this.defaultSigner.defaultSignerMobile)) {
        this.$message.error('请输入正确的签署人手机号')
      } else
        return updatedefaultsign({
          ...this.defaultSigner,
          id: this.$route.query.entityId
        }).then((res) => {
          if (res.code === 200) {
            this.sign = false
            this.getfindsupplierall()
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    updateSingnerNew(record) {
      signtorySet({
        signId: record.id,
        id: this.$route.query.entityId
      }).then((res) => {
        if (res.code === 200) {
          this.getfindsupplierall()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 签署人列表
    getfindsupplierall() {
      this.loadingtable = true
      // 主体下签署人列表
      signtory({
        entityCharacter: 'P',
        entityId: this.$route.query.entityId
      }).then((res) => {
        this.loadingtable = false
        const defaultObj = res.data.signToryVoList.find(item => item.isDefault) || {}
        this.defaultSigner.id = defaultObj.id
        this.defaultSigner.defaultSignerName = defaultObj.signerName
        this.defaultSigner.defaultSignerIdCard = defaultObj.signerIdCard
        this.defaultSigner.defaultSignerMobile = defaultObj.signerMobile
        this.defaultSigner.signatureImgUrl = defaultObj.signatureImgUrl
        this.isAutomaticSigning = res.data.automaticSigning === 'YES'
        this.signToryVoList = res.data.signToryVoList.map((item) => {
          return { ...item, isNo: true }
        })
        this.cacheData = res.data.signToryVoList.map((item) => {
          return { ...item }
        })
        this.purchaserSealList = res.data.purchaserSealList.map((item) => {
          return {
            uid: item.id,
            status: 'done',
            isDefault: item.isDefault,
            name: item.extSignNum,
            url: item.sealUrl
          }
        })
        // this.setSellNumb()
      }).catch(() => this.loadingtable = false)
    },
    onSearch() {},
    // 关联签署人
    relevance(id) {
      this.id = id
      this.rele = false
    },
    newEdit (record) {
      this.$refs.editSign.type = record ? Object.assign({}, record) : {}
      this.$refs.editSign.visible = true
    },
    // 编辑
    edit(key) {
      const newData = [...this.signToryVoList]
      const target = newData.filter((item) => key=== item.id)[0]
      target.data = target
      this.editingKey = key
      if (target) {
        target.editable = true
        this.signToryVoList = newData
      }
    },
    // 删除
    onDelete(id) {
      delsignerpurchaser({ id: id }).then((res) => {
        if (res.code === 200) {
          this.getfindsupplierall()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleChange(value, key, column) {
      const newData = [...this.signToryVoList]
      const target = newData.filter((item) => key === item.id)[0]
      if (target) {
        target[column] = value
        this.signToryVoList = newData
      }
    },
    // 卖家签署人添加操作
    addAction(obj) {
      const objs = {
        purchaserId: this.$route.query.entityId,
        signerIdCard: obj.signerIdCard,
        signerMobile: obj.signerMobile,
        signerName: obj.signerName
      }
      add(objs).then((res) => {
        if (res.code === 200) {
          this.editingKey = ''
          this.$message.success(res.message)
          this.getfindsupplierall()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    addS() {
      if (this.cacheData.find((item) => item.key === -1)) return
      const obj = {
        id: -1,
        signerIdCard: '',
        signerMobile: '',
        signerName: ''
      }
      this.signToryVoList.push(obj)
      this.cacheData.push(obj)
      this.edit(obj.id)
    },
    // 编辑
    save(record) {
      if (!record.signerName) {
        this.$message.error('名字不能为空')
      } else if (!car.test(record.signerIdCard)) {
        this.$message.error('请输入正确的身份证')
      } else if (!myreg.test(record.signerMobile)) {
        this.$message.error('请输入正确的手机号')
      } else {
        if (record.id === -1) {
          this.addAction(record)
        } else {
          updatesignerpurchase({
            id: record.id,
            signerName: record.signerName,
            signerIdCard: record.signerIdCard,
            signerMobile: record.signerMobile
          }).then((res) => {
            if (res.code === 200) {
              this.editingKey = ''
              this.$message.success(res.message)
              this.getfindsupplierall()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      }
    },
    // 公章操作
    setSellNumb() {
      if (document.getElementById('icon-default')) {
        document.getElementById('icon-default').remove()
      }
      const uploadItem = document.getElementsByClassName('ant-upload-list-item')
      const imgBox = document.getElementsByClassName(
        'ant-upload-list-item-info'
      )
      let chArr = document.body.getElementsByClassName("purchaser-id");
      if (chArr.length) {
        for(var i = chArr.length - 1; i >= 0; i--){
          if (chArr[i]) {
            chArr[i].parentNode.removeChild(chArr[i]); 
          }
        }
      }
      this.$nextTick(() => {
        this.isReady = false
        setTimeout(() => {
        this.purchaserSealList.forEach((item, index) => {
          var div = document.createElement('div')
          var txt = document.createTextNode(item.name)
          div.className = 'purchaser-id'
          div.appendChild(txt)
          // 如果是默认印章
          if (item.isDefault) {
            uploadItem[index].className = this.defaultClass + ' rell-defailt'
          } else {
            uploadItem[index].className = this.defaultClass
          }
          imgBox[index].setAttribute('uId', item.uid)
          uploadItem[index].appendChild(div)
        })
        this.isReady = true
        }, 310)
      })
    },
   // 公章列表
    getSealList () {
      list({ purchaserId: this.$route.query.entityId }).then(res => {
        if (res.code === 200) {
          this.purchaserSealList = res.data.map(item => {
            return {
              uid: item.id,
              name: `${item.extSignNum}`,
              status: 'done',
              url: `${item.sealUrl}`,
              isDefault: item.isDefault
            }
          })
          this.setSellNumb()
        } else {
          this.$notification.error({
            message: '错误',
            description: res.message
          })
        }
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    cancel(key) {
      if (key === -1) {
        const newData = [...this.signToryVoList]
        this.signToryVoList = newData.filter((item) => key !== item.id)
        this.cacheData = [...this.signToryVoList]
        this.editingKey = ''
      } else {
        const newData = [...this.signToryVoList]
        const target = newData.filter((item) => key === item.id)[0]
        this.editingKey = ''
        if (target) {
          Object.assign(
            target,
            this.cacheData.filter((item) => key === item.id)[0]
          )
          delete target.editable
          this.signToryVoList = newData
        }
      }
    },
    // 删除公章
    onSealDelete(file) {
      if (document.getElementById('icon-default')) {
        document.getElementById('icon-default').remove()
      }
      this.$confirm({
        title: '提示',
        content: '确定删除？',
        onOk: () => {
          deleteseal({ sealId: file.uid }).then((res) => {
            if (res.code === 200) {
              this.$notification.success({
                message: '成功',
                description: '删除成功！'
              })
              this.getSealList()
            } else {
              this.$notification.error({
                message: '错误',
                description: res.message
              })
            }
          })
        },
        onCancel() {}
      })
    },
    // 获取签名图片url
    signatureUrl({signatureUrl, singerId}) {
      console.log({singerId,enterpriseType: 'purchaser', signatureUrl});
      signerSig({singerId,enterpriseType: 'purchaser', signatureUrl}).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.getfindsupplierall()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    async customRequest({ file }, id) {
      if (file.type.indexOf('image') === -1) {
        this.$message.error('请上传正确的格式')
        return
      }
      console.log(file, id);
      this.$nextTick(async ()=>{
        this.$refs.modal.fileList = [file.originFileObj]
        this.$refs.modal.rowId = id
        console.log(file);
        this.$refs.modal.options.img =await this.getBase64(file.originFileObj)
         this.$refs.modal.previews.url =await this.getBase64(file.originFileObj)
         this.$refs.modal.edit(1,'buyer')
      })
    },
    checkChange (e) {
      this.isAutomaticSigning = e.target.checked
      signerConfig({entityId: this.$route.query.entityId, enterpriseType: 'purchaser', isAutomaticSigning: this.isAutomaticSigning}).then(res => {
        if (res.code === 200) {
          this.isAutomaticSigning = e.target.checked
          this.$message.success(res.message)
        } else {
          this.isAutomaticSigning = !e.target.checked
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>
<style>
.popover-custom {
  z-index: 1;
}
.popover-custom .ant-popover-inner-content {
  padding: 4px 10px !important;
}
</style>
<style lang="less" scoped>

.signset {
  border-top: 1px solid #ccc;
  background-color: #ffffff;
  .mainbody {
    padding: 20px 24px;
    .sign-title {
      font-size: 16px;
      font-weight: 650;
      color: rgba(0, 0, 0, 0.847058823529412);
      padding: 10px 0;
    }
    .lh32 {
      line-height: 30px;
    }
    .lh44 {
      line-height: 44px;
    }
    .color {
      color: rgb(24, 144, 255);
    }
    .bgc {
      background-color: rgb(24, 144, 255);
    }
    .dynamic-delete-button {
      cursor: pointer;
      position: relative;
      top: 4px;
      font-size: 24px;
      color: #999;
      transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
      color: #777;
    }
    .dynamic-delete-button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
.table-foot {
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  height: 46px;
  line-height: 46px;
  cursor: pointer;
  user-select: none;
}

/deep/ .ant-table-placeholder {
  display: none;
}
.table-foot {
  text-align: center;
}
.product-img {
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.427450980392157);
  background-color: #eee;
  border-radius: 5%;
  img {
    width: 66px;
  }
  .img-config {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
/deep/ .purchaser-id {
  position: absolute;
  // color: red;
  z-index: 2;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  left: 0;
}
/deep/ .rell-defailt {
  border-color: red;
  position: relative;
  &::after {
    content: '';
    height: 20px;
    width: 20px;
    background-image: url('../../../assets/images/default.png');
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
  }
  &::before {
    content: '';
    width: 20px;
    background-size: cover;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.auto-sign {
  margin: 32px 0 !important;
}
.hint {
  background-color: rgba(242, 242, 242, 1);
  color: #7F7F7F;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 5px;
  line-height: 20px;
}
.signature {
  width: 70px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(215, 215, 215, 1);
  color: #AAAAAA;
  background-size: cover;
  cursor: pointer;
  position: relative;
  transition: all .3s;
  .swap {
    color: #fff;
    position: absolute;
    top: -5px;
    right: -5px;
  }
  &:hover {
    color: @primary;
    border: 1px solid @primary;
  }
}
.sign-input {
  width: 200px;
}
.font-error {
  color: #d81e06
}

.title-table {
  position: relative;
  .title-icon {
    position: absolute;
    right: -12px;
    top: -6px;
    color: #faad14;
  }
}
</style>