<template>
  <div class="container" style="padding-bottom: 72px">
    <div class="tabs-chunk">
      <p class="tabs-title">帐号权限管理</p>
      <a-table :data-source="roledata" :columns="roleColumns" :pagination="false" :loading="loading"
        :rowKey="(record,index)=>{return index}">
        <template slot="role" slot-scope="text,record">
          <a-badge :color="record.role === '管理员' ? 'cyan' : 'green'" :text="record.role" />
        </template>
        <template slot="action" slot-scope="text,record">
          <a-space>
          <a-button @click="edits(record)" v-if="record.role!=='管理员'">编辑</a-button>&nbsp;&nbsp;
         <a-button v-if="record.role!=='管理员'">
          <a-popconfirm
           cancelText="取消"
          okText="确定"
          title="确定删除?"
         :disabled="editingKey !== ''" @confirm="() => delUserRole(record.id)"
        >
          <a href="javascript:;" >删除</a>
        </a-popconfirm>
         </a-button>
         </a-space>
        </template>
      </a-table>
      <div class="table-foot" @click="roleModal.off = true">
        <a-icon type="plus"></a-icon>
      </div>
    </div>


    <div class="tabs-chunk">
      <div class="title-row">
        <p class="tabs-title">货运助手小程序权限管理</p>
        <div class="v-code">
          <a-tooltip placement="bottom" arrowPointAtCenter>
            <div slot="title">
              <img src="../../../assets/images/xcx-code.jpg" width="110"/>
              <div class="sm-message">微信扫一扫</div>
            </div>
            <img src="../../../assets/images/v-code.png" width="16" class="xcx-code"/> 小程序码
          </a-tooltip>
        </div>
      </div>
      
      <a-table :data-source="xcxRoleData" :columns="xcxRoleColumns" :pagination="false" :loading="xcxloading"
        :rowKey="(record,index)=>{return index}">
        <template slot="role" slot-scope="text,record">
          <a-badge :color="record.roleName === '业务员' ? 'cyan' : 'green'" :text="record.roleName" />
        </template>
        <template slot="action" slot-scope="text,record">
          <a-space>
          <a-button @click="editXcx(record)" >编辑</a-button>&nbsp;&nbsp;
         <a-button >
          <a-popconfirm
            cancelText="取消"
            okText="确定"
            title="确定删除?"
           @confirm="() => delXcxRole(record.userId)"
        >
          <a href="javascript:;" >删除</a>
        </a-popconfirm>
         </a-button>
         </a-space>
        </template>
      </a-table>
      <div class="table-foot" @click="isShowAddXcx = true">
        <a-icon type="plus"></a-icon>
      </div>
    </div>


    <!-- 添加子账号 -->
    <a-modal v-model="roleModal.off" title="添加子账号" :width="600" centered :footer="null"
      @cancel="restartRole();roleModal.off=false">
      <div class="add-modal">
        <a-row type="flex">
          <a-col :span="4" class="add-right">用户账号：</a-col>
          <a-col :span="20">
            <SearchSelect ref="SearchSelect" :list="roleModal.list" @search="searchUser($event)" :focu="false" @select="roleModal.value = $event.id"/>
          </a-col>
          <a-col :span="4" class="add-right">权限：</a-col>
          <a-col :span="20">
            <a-select :value="roleModal.roleValue" style="width: 50%" @change="e => {roleModal.roleValue = e}">
              <!-- <a-select-option :value="1">
                管理员
              </a-select-option> -->
              <a-select-option :value="2">
                业务员
              </a-select-option>
              <a-select-option :value="3">
                财务
              </a-select-option>
              <a-select-option :value="4">
                经理
              </a-select-option>
            </a-select>
          </a-col>
          <a-row class="select-info" v-if="roleModal.item">
            <a-col :span="12">
              用户名：{{roleModal.item.nickName}}
            </a-col>
            <a-col :span="12">
              使用者姓名：{{roleModal.item.userName}}
            </a-col>
            <a-col :span="12">
              邮箱：
            </a-col>
            <a-col :span="12">
              手机号码：{{roleModal.item.regMobile}}
            </a-col>
          </a-row>
          <a-col :span="24" style="text-align: center;margin-top: 12px">
            <a-space :size="24">
              <a-button @click="restartRole();">重置</a-button>
              <a-button type="primary" @click="enterModal">确定</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </a-modal>
    <!-- 编辑子账号 -->
    <a-modal v-model="edit.off" title="编辑权限" :width="600" centered :footer="null"
      @cancel="rest();edit.off=false">
      <div class="add-modal">
        <a-row type="flex">
          <a-row class="select-info" v-if="edit.item" style="margin-bottom: 24px">
            <a-col :span="12">
              使用者姓名：{{edit.item.name}}
            </a-col>
            <a-col :span="12">
              权限：{{edit.item.role}}
            </a-col>
          </a-row>
          <a-col :span="4" class="add-right">权限：</a-col>
          <a-col :span="20">
            <a-select :value="edit.roleValue" style="width: 50%" @change="e => {edit.roleValue = e}" >
              <!-- <a-select-option :value="1">
                管理员
              </a-select-option> -->
              <a-select-option :value="2">
                业务员
              </a-select-option>
              <a-select-option :value="3">
                财务
              </a-select-option>
              <a-select-option :value="4">
                经理
              </a-select-option>
            </a-select>
          </a-col>
       
          <a-col :span="24" style="text-align: center;margin-top: 12px">
            <a-space :size="24">
              <a-button @click="rest();">重置</a-button>
              <a-button type="primary" @click="sur">确定</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </a-modal>

    <!-- 添加小程序 -->
    <a-modal v-model="isShowAddXcx" :title="isEdit?'编辑小程序权限':'添加小程序权限'" :width="600" centered :footer="null"
      @cancel="restartXcxAdd">
      <div class="add-modal">
        <a-row type="flex">
          <a-col :span="4" class="add-right">账号：</a-col>
          <a-col :span="20">
            <a-input v-model="addXcxForm.phone" :disabled="isEdit" placeholder="请输入手机号" />
          </a-col>
          
          <a-col :span="4" class="add-right">姓名：</a-col>
          <a-col :span="20">
            <a-input v-model="addXcxForm.realName" placeholder="请输入姓名" />
          </a-col>
          <a-col :span="4" class="add-right">下游归属：</a-col>
          <a-col :span="20">
            <a-select :value="addXcxForm.downstreamId" :disabled="isEdit" style="width: 50%" @change="e => {addXcxForm.downstreamId = e}" placeholder="请选择下游归属">
              <a-select-option :value="ds.id" v-for="ds in xcxDsList" :key="ds.id">
                {{ds.downstreamName}}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="4" class="add-right">权限：</a-col>
          <a-col :span="20">
            <a-select :value="addXcxForm.roleId" style="width: 50%" @change="e => {addXcxForm.roleId = e}" placeholder="请选择权限">
              <a-select-option :value="role.value" v-for="role in xcxRoleList" :key="role.value">
                {{role.label}}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="24" style="text-align: center;margin-top: 12px">
            <a-space :size="24">
              <a-button @click="restartXcxAdd();">取消</a-button>
              <a-button type="primary" @click="onHandlerSumbitEdit">确定</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { auth, getparty, add, deletepartycontrol } from '@/api/seller'
import {updatepartycontrolentity, roleList, dsList, xcxAppList, xcxAppListAdd, xcxAppListDelete, xcxAppListUpdata } from '../../../api/buyerCenter'
import SearchSelect from '@/components/SearchSelect'
import {mapGetters} from 'vuex'

const roleColumns = [
  {
    title: '子账号',
    dataIndex: 'username',
  },
  {
    title: '使用者姓名',
    dataIndex: 'name',
  },
  {
    title: '权限',
    dataIndex: 'role',
    scopedSlots: { customRender: 'role' }
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' }
  },
]
const xcxRoleColumns = [
  {
    title: '子账号',
    dataIndex: 'phone',
  },
  {
    title: '使用者姓名',
    dataIndex: 'realName',
  },
  {
    title: '下游归属',
    dataIndex: 'downstreamName',
  },
  {
    title: '权限',
    dataIndex: 'roleName',
    scopedSlots: { customRender: 'role' }
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' }
  },
]


const type = {
  // 1: '管理员',
  2: '业务员',
  3: '财务',
  4: '经理',
}
import cookie from '@/utils/js-cookie.js'

export default {
  props: ['id','name'],
  components: {
    SearchSelect
  },
  computed: {
    ...mapGetters(['getRoleP']),
  },
  data() {
    return {
      editingKey:'',
      loading: false,
      roleColumns,
      roledata: [],
      roleModal: {
        off: false,
        value: undefined,
        list: [],
        fetching: false,
        roleValue: 2,
        item: undefined
      },
      xcxRoleColumns,
      edit: {
        off: false,
        value: undefined,
        list: [],
        roleValue: 2,
        item: undefined
      },
      editinfo:{
          id:null,
      },
      
      xcxloading:false,
      isShowAddXcx:false,
      xcxDsList:[],//下游列表
      xcxRoleList:[],//角色列表
      xcxRoleData:[], //出入库小程序列表
      isEdit:false,//是否是变价
      addXcxForm:{
        downstreamId:'',
        roleId:'',
        realName:'',
        phone:''
      },
    }
  },
  mounted() {
      this.auth()
      this.getXcxAppList()
      this.getRoleList()
      this.getDsList()
  },
  methods: {
    onHandlerSumbitEdit(){
      if(this.isEdit){
        this.onHandSumbit()
        return
      }
      this.onHandlerSumbitAdd()
    },
    editXcx(editForm){
      this.isEdit = true
      this.isShowAddXcx = true;
      this.addXcxForm = editForm
      this.addXcxForm.downstreamId = this.xcxDsList.find(item=>item.downstreamName ==editForm.downstreamName ).id
    },
    getDsList(){
      dsList({
        pageNum:1,
        pageSize:100,
        purchaserId:this.id
      }).then(res=>{
        if (res.code === 200) {
          this.xcxDsList = res.data.list
          this.addXcxForm.downstreamId = this.xcxDsList[0].id
        }
      })
    },
    getRoleList(){
      roleList().then(res=>{
        if (res.code === 200) {
          this.xcxRoleList = res.data.map(item=>{
            return {
              value: item.id,
              label: item.name,
            }
          })
          this.addXcxForm.roleId = this.xcxRoleList[0].value
        }
      })
    },
    //小程序用户列表
    getXcxAppList(){
      this.xcxloading = true
      xcxAppList(this.id).then(res=>{
        this.xcxloading = false
        if (res.code === 200) {
          this.xcxRoleData = res.data
        }
      })
    },
    onHandSumbit(){
      if(!this.addXcxForm.realName){
        this.$message.error('请输入姓名')
        return
      }
      xcxAppListUpdata(
        this.addXcxForm
      ).then(res=>{
        if (res.code === 200) {
          this.$message.success(res.message)
          this.restartXcxAdd()
          this.getXcxAppList()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    delXcxRole(appUserId){
      xcxAppListDelete(appUserId).then(res=>{
        if (res.code === 200) {
          this.$message.success(res.message)
          this.getXcxAppList()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    //重置小程序权限表单
    restartXcxAdd(){
      this.addXcxForm = {
        downstreamId:'',
        roleId:'',
        realName:'',
        phone:''
      }
      this.isEdit = false
      this.isShowAddXcx = false
    },
    //添加小程序权限
    onHandlerSumbitAdd(){
      var myreg = /^1[3-9][0-9]{9}$/
      if(!this.addXcxForm.phone||!myreg.test(this.addXcxForm.phone)){
        this.$message.error('请输入正确手机号')
        return
      }
      if(!this.addXcxForm.realName){
        this.$message.error('请输入姓名')
        return
      }
      xcxAppListAdd(this.addXcxForm).then(res=>{
        if (res.code === 200) {
          this.$message.success(res.message)
          this.restartXcxAdd()
          this.getXcxAppList()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    // 添加时input的宽度
    width(key) {
      const obj = {
        'signerName': '80px',
        'signerIdCard': '175px',
        'signerMobile': '120px',
      }
      return obj[key]
    },
    // 获取权限管理数据
    auth() {
      this.loading = true
      auth({ entityCharacter: 'P', entityId:this.$route.query.entityId}).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.roledata = res.data
        }
      })
    },
    //   搜索
    searchUser(username) {
      if (!username) return this.$message.error('请输入用户名,姓名或者手机号模糊匹配') 
      this.roleModal.fetching = true
      getparty({ username:username,entityCharacter:'P',entityId:this.$route.query.entityId }).then(res => {
        this.roleModal.fetching = false
        if (res.code == 200) {
          this.roleModal.list = res.data.filter(item => {
            return item.nickName !== (this.roledata.find(i => i.username === item.nickName) || {}).username
          })
        }
      })
    },
    //   账号权限管理 a-modal选中
    searchChange(e) {
      this.roleModal.value = e
      this.roleModal.item = this.roleModal.list.find(item => item.id === this.roleModal.value)
    },
    // a-modal添加
    enterModal() {
      if (this.name===''||this.id===null) {
        return
      }
      const config = { 
        entityCharacter: 'P',
        entityId: this.id, 
        commercialName: this.name, 
        authRoleId: this.roleModal.roleValue, 
        authRoleName: type[this.roleModal.roleValue],
        partyId: this.roleModal.value
      }
      add(config).then(res => {
        if (res.code === 200) {
          this.restartRole()
          this.roleModal.off = false
          this.$message.success(res.message)
          this.auth()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // a-modal 重置
    restartRole() {
      this.roleModal = {
        off: true,
        value: undefined,
        list: [],
        fetching: false,
        roleValue: 2,
        item: undefined
      }
      this.$refs.SearchSelect.restart()
    },
    // 删除子账号
    delUserRole(id) {
        deletepartycontrol({ entityCharacter: 'p', entityId: this.id, id }).then(res => {
            if (res.code === 200) {
                this.auth()
                this.$message.success(res.message)
            } else {
                this.$message.error(res.message)
            }
        })
    },
    // 编辑子账号
    edits(e){
      console.log(e);
        this.edit.off=true
        this.edit.item= e
        this.editinfo.id=e.id
        this.edit.roleValue=Number(this.findKey(e.role))
    },
    findKey (value, compare = (a, b) => a === b) {
      return Object.keys(type).find(k => compare(type[k], value))
    },
    rest(){
        this.edit={
        off: true,
        value: undefined,
        list: [],
        roleValue: 2,
        item: undefined
        }
    },
    sur(){
      const config = { 
        authRoleId:this.edit.roleValue,
        authRoleName:type[this.edit.roleValue],
        id:this.editinfo.id,
      }
      updatepartycontrolentity(config).then(async res=>{
        if (res.code===200) {
        const find = this.roledata.find(item => item.id == this.editinfo.id)
        if (find.username == cookie.get('username')) {
          await this.$store.dispatch("getuserMenu")
          const obj = {
              "PM001": '/buyercenter',
              "PM002": '/buyercenter/order-management',
              "PM003": '/buyercenter/advance-management',
              "PM004": '/buyercenter/payment-management',
              "PM005": '/buyercenter/balance-management',
              "PM006": '/buyercenter/batch-management',
          }
          const menu = this.getRoleP.find(item => item.entityId == this.$route.query.entityId) || {}
          // console.log(this.getRoleP, menu,obj[menu.menuListVoList[0].menuCode], this.$route.query.entityId);
          console.log(obj[menu.menuListVoList[0].menuCode] + (this.$route.query.entityId && `?entityId=${this.$route.query.entityId}`));
          menu.menuListVoList.length && this.$router.replace(obj[menu.menuListVoList[0].menuCode] + (this.$route.query.entityId && `?entityId=${this.$route.query.entityId}`))
        }
        this.$message.success(res.message)
        this.auth()
          this.edit.off = false
        }else{
        this.$message.error(res.message)
        }
     })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .check-color {
  background: #e2e8da;
}
/deep/ .ant-col {
  margin-bottom: 12px;
}
.container {
  border-top: 1px solid #e9e9e9;
  background: #fff;
  padding: 22px 32px;
  font-size: 14px;
  .tabs-title {
    font-size: 16px;
    font-weight: 650;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 12px;
  }
}
.tabs-chunk {
  margin-bottom: 32px;
  .table-foot {
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    user-select: none;
  }
  /deep/ .ant-table-placeholder {
    display: none;
  }
}
.tabs-chunk {
  margin-bottom: 32px;
  .table-foot {
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    user-select: none;
  }
  /deep/ .ant-table-placeholder {
    display: none;
  }
}
.add-modal {
  padding: 12px 32px;
}
.add-right {
  line-height: 32px;
  text-align: right;
}
.select-info {
  border-width: 1px;
  border-style: solid;
  border-color: #aaaaaa;
  background: #f2f2f2;
  padding: 8px 12px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 12px;
  /deep/ .ant-col {
    margin-bottom: 0;
  }
}
.font-error {
  color: @error;
  font-size: 16px;
}
.title-row{
  display: flex;
  // justify-content: space-between;
  .v-code{
    margin-left: 30px;
    font-size: 12px;
    margin-top: 3px;
  }
  .xcx-code{
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
 
}
.sm-message{
    text-align: center;
    margin-top: 5px;
  }
</style>