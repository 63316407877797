<template>
  <a-modal centered :title="titleType[type.uploadCertStatus || '']" :zIndex="100" :visible="visible" footer="" :width="918" @cancel="cancel">
    <a-spin :spinning="spinning && type.uploadCertStatus">
        <a-row class="row-m">
            <template v-if="type.uploadCertStatus !== 'SUCCESS'">
                <a-col :span="24" class="note col-t" style="width: 100%">*为确保签署人身份、意愿的真实性，请上传身份证照片</a-col>
                <a-col :span="24" class="col-s">
                    <div class="upload-content">
                    <!-- <div class="upload-msg"><span class="red">*</span>上传的身份证照片需横向、四角完整并尽量满屏（单张照片不超过3M）</div> -->
                    <a-space :size="60">
                        <a-spin :spinning="idCardLoading && uploadLabel === 'idCardFrontUrl'">
                        <img class="card" :class="form.idCardFrontUrl && 'hasUrl'"
                            :src="form.idCardFrontUrl || require('@/assets/images/card/front.png')"
                            @click="type.uploadCertStatus !== 'SUCCESS' && uploadIdCard('idCardFrontUrl')">
                            <p class="note">*身份证头像面：横向图片、四角完整并尽量满屏（单张照片不超过3M）</p>
                        </a-spin>
                        <a-spin :spinning="idCardLoading && uploadLabel === 'idCardBackUrl'">
                        <img class="card" :class="form.idCardBackUrl && 'hasUrl'"
                            :src="form.idCardBackUrl || require('@/assets/images/card/back.png')"
                            @click="type.uploadCertStatus !== 'SUCCESS' && uploadIdCard('idCardBackUrl')">
                            <p class="note">*身份证国徽面：横向图片、四角完整并尽量满屏（单张照片不超过3M）</p>
                        </a-spin>
                    </a-space>
                    </div>
                </a-col>
            </template>
            <a-col :span="24">
                <div class="fl label-m"><span class="red">*</span> 姓名：</div>
                <div class="value">
                    <a-input v-if="!type.uploadCertStatus" v-model="form.signerName" placeholder="签署人姓名" style="width: 342px"></a-input>
                    <span v-else>{{type.signerName}}</span>
                </div>
            </a-col>
            <a-col :span="24">
                <div class="fl label-m"><span class="red">*</span> 身份证号：</div>
                <div class="value">
                    <a-input v-if="!type.uploadCertStatus" v-model="form.signerIdCard" placeholder="签署人身份证号" style="width: 342px"></a-input>
                    <span v-else>{{type.signerIdCard}}</span>
                </div>
            </a-col>
            <a-col :span="24">
                <div class="fl label-m"><span class="red">*</span> 手机号：</div>
                <div class="value">
                    <a-input v-model="form.signerMobile" placeholder="签署人手机号" style="width: 342px"></a-input>
                </div>
            </a-col>

            <a-col :span="24" align="center">
                <a-space :size="32" class="space-m">
                    <a-button size="large" @click="cancel">取消</a-button>
                    <a-button size="large" type="primary" :loading="loading" @click="enter">确定</a-button>
                </a-space>
            </a-col>
        </a-row>
    </a-spin>
    <input v-show="false" ref="addImage" alt="" class="upload" type="file" name="input" multiple="multiple" accept="image/png,image/jpeg,image/gif,image/jpg" @change="getImgFile" />
  </a-modal>
</template>

<script>
// import { uploadPhoto } from "@/api/utils";
import { add, signtoryInfo, updatesignerpurchase } from '@/api/buyerCenter'
import { cardOcr, cardBackOcr } from '@/api/seller'
import cropperModel from '@/views/application/component/cropperModel.vue'
export default {
    props: ['purchaserId'],
    data() {
        return {
            visible: false,
            spinning: false,
            loading: false,
            uploadLabel: '',
            type: {}, // '':新增， 'SUCCESS': 编辑修改手机号, 'UNTREATED': 补全信息
            titleType: {
                '': '新增签署人',
                'SUCCESS': '修改签署人',
                'UNTREATED': '修改签署人'
            },
            idCardLoading: false,
            form: {
                id: null,
                idCardFrontUrl: null,
                idCardBackUrl: null,
                signerName: null,
                signerIdCard: null,
                signerMobile: null
            }
        }
    },
    watch: {
        visible (val) {
            if (val && this.type.uploadCertStatus) {
                this.signtoryInfo()
            }
        }
    },
    methods: {
        signtoryInfo() {
            const config = {
                entityCharacter: 'P',
                entityId: this.purchaserId,
                id: this.type.id
            }
            this.spinning = true
            signtoryInfo(config).then(res => {
                this.spinning = false
                if (res.code == 200 && this.visible) {
                    const {idCardFrontUrl,idCardBackUrl,signerName,signerIdCard,signerMobile,id} = res.data
                    this.form = {idCardFrontUrl,idCardBackUrl,signerName,signerIdCard,signerMobile,id}
                    return
                } 
                this.$message.error(res.message)
            }).catch(() => this.spinning = false)
        },
        uploadIdCard(e) {
            if (this.idCardLoading) {
                return
            }
            this.uploadLabel = e
            this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
        },
        getImgFile (e) {
            if (e.target.files[0].type.indexOf('image') === -1) {
                this.$message.error('请上传正确的格式')
                e.target.value = null
                return
            }
            if ((e.target.files[0].size / 1024 / 1024) > 3) {
                this.$message.error('图片大小超过3MB，请进行图片压缩处理再上传识别')
                e.target.value = null
                return
            }
            const fileInfo = {
                uid: '-1',
                name: e.target.files[0].name,
                status: 'done'
            }
            const fr = new FileReader()
            fr.readAsDataURL(Array.from(e.target.files)[0])
            const that = this
            fr.onload = e => {
                this.$dialog(cropperModel,
                    {
                    record: e.target.result,
                    fileInfo,
                    isFront: this.uploadLabel === 'idCardFrontUrl',
                    on: {
                        ok (e) {
                        console.log('ok:', e)
                        that.idCardLoading = true
                        if (that.uploadLabel === 'idCardFrontUrl') {
                            cardOcr(e).then(res => {
                            that.idCardLoading = false
                            if (res.code !== 200) {
                                that.form[that.uploadLabel] = ''
                                that.$message.error(res.message)
                                return
                            }
                            that.form[that.uploadLabel] = e
                            if (that.type.uploadCertStatus === 'UNTREATED') {
                                if (res.data.idNumber !== that.form.signerIdCard && res.data.idNumber.toUpperCase() !== that.form.signerIdCard.toUpperCase()) {
                                    that.form[that.uploadLabel] = ''
                                    that.$message.error('上传的身份证与当前签属人身份证号不对应')
                                }
                                return
                            }
                            console.log(res.data);
                            that.form.signerName = res.data.name
                            that.form.signerIdCard = res.data.idNumber
                            }).catch(() => that.idCardLoading = false)
                        }
                        if (that.uploadLabel === 'idCardBackUrl') {
                            cardBackOcr(e).then(res => {
                            that.idCardLoading = false
                            if (res.code !== 200) {
                                that.form[that.uploadLabel] = ''
                                that.$message.error(res.message)
                                return
                            }
                            that.form[that.uploadLabel] = e
                            }).catch(() => that.idCardLoading = false)
                        }
                        },
                        cancel () {
                        console.log('cancel:')
                        // that.$refs._component.$emit('close')
                        },
                        close () {
                        console.log('close')
                        }
                    }
                    },
                    {
                    title: '上传身份证信息',
                    width: 715,
                    centered: true,
                    footer: ''
                })
            }
            e.target.value = null
        },
        enter() {
            if (!this.type.uploadCertStatus) {
                // 添加签署人
                const objs = {
                    purchaserId: this.purchaserId,
                    ...this.form,
                    id: undefined
                }
                if (!this.form.idCardFrontUrl) {
                    this.$message.error('请上传身份证正面')
                    return
                }
                if (!this.form.idCardBackUrl) {
                    this.$message.error('请上传身份证反面')
                    return
                }
                if (!this.form.signerName) {
                    this.$message.error('请输入签署人姓名')
                    return
                }
                if (!this.form.signerIdCard) {
                    this.$message.error('请输入签署人身份证号')
                    return
                }
                if (!this.form.signerMobile) {
                    this.$message.error('请输入签署人手机号')
                    return
                }
                this.loading = true
                add(objs).then((res) => {
                    this.loading = false
                    if (res.code === 200) {
                        this.$message.success(res.message)
                        this.$emit('ok')
                        this.cancel()
                    } else {
                        this.$message.error(res.message)
                    }
                }).catch(() => this.loading = false)
            } else {
                if (!this.form.idCardFrontUrl) {
                    this.$message.error('请上传银行卡正面')
                    return
                }
                if (!this.form.idCardBackUrl) {
                    this.$message.error('请上传银行卡反面')
                    return
                }
                this.loading = true
                updatesignerpurchase(this.form).then(res => {
                    this.loading = false
                    if (res.code === 200) {
                        this.$message.success(res.message)
                        this.$emit('ok')
                        this.cancel()
                    } else {
                        this.$message.error(res.message)
                    }
                }).catch(() => this.loading = false)
            }
            // else if (this.type.uploadCertStatus === 'SUCCESS') {

            // } else if (this.type.uploadCertStatus === 'UNTREATED') {

            // }
        },
        cancel() {
            this.visible = false
            this.form = {
                idCardFrontUrl: null,
                idCardBackUrl: null,
                signerName: null,
                signerIdCard: null,
                signerMobile: null
            }
        }
    }
}
</script>

<style lang="less" scoped>
.row-m {
    font-family: 'Microsoft YaHei ', 'Microsoft YaHei', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    padding-left: 54px;
    .col-t {
        margin-bottom: 6px !important;
    }
    .col-s {
        margin-bottom: 30px !important;
    }
    /deep/ .ant-col {
        margin-bottom: 20px;
    }
}
.upload-content {
  margin-top: 12px;
  @media screen and (max-width: 1024px) {
    padding-left: 114px;
  }
  .upload-msg {
    width: 762px;
    margin: 0 auto 25px;
  }
  .upload {
    background-size: contain;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    border: 1px dashed #1890ff;
    cursor: pointer;
    display: inline-block;
    width: 365px;
    height: 205px;
    font-weight: 700;
    color: #1890ff;
    font-size: 40px;
    text-align: center;
    line-height: 205px;
  }
  .card {
    cursor: pointer;
    width: 349px;
    height: 214px;
    margin-bottom: 10px;
  }
  .hasUrl {
    border-radius: 19px;
    border: 2px solid #d0dcef;
    object-fit: contain;
  }
}
.space-m {
    margin: 30px 0;
    button {
        width: 110px;
    }
}
.label-m {
    text-align: right;
    width: 86px;
    line-height: 32px;
}
.value {
    line-height: 32px;
    margin-left: 86px;
}
.note {
    font-size: 16px;
    color: #d9001b;
    line-height: 24px;
    width: 349px;
}
.red {
    color: #d9001b;
}
</style>